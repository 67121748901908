a {
  text-decoration: none !important;
}

.bg-color {
  background-color: blue !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main.main-posts-list {
  display: flex;
}

.blog-banner-section {
  position: relative;
  background-image: url("./blog_image.jpg");
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.container.text-center.banner-content {
  z-index: 1;
}

.blog-banner-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  color: white;
}

@media only screen and (max-width: 992px) {
  main.main-posts-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .blog-banner-title {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  article.article.d-flex {
    flex-direction: column;
    align-items: c;
  }

  .article-img-column {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.content-wrap {
  padding: 61px 0 64px;
  display: flex;
}

.article {
  margin-bottom: 30px;
}

.article-img-column {
  flex: 1;
}

.article-text-column {
  flex: 2;
  padding-left: 30px;
  padding-top: 5px;
  padding-right: 30px;
}

.tab-list {
  margin-bottom: 15px;
}

.tab-link {
  background: linear-gradient(to right, #ff5917, #ff9317);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  color: #1d407f;
  padding: 2px 5px;
}


.tab-link:hover {
  text-decoration: underline;
  text-decoration-color: #1d407f;
}

.article-title {
  /* margin-bottom: 15px; */
  text-transform: uppercase;
  font-size: 20px;
}

.article-title-link {
  text-decoration: none;
  color: #000;
}

.article-title-link:hover {
  color: #1d407f;
}

.article-datetime {
  margin-right: 5px;
  font-size: 12px;
  line-height: 40px;
  color: #888888;
  padding-right: 20px;
}

.article-author {
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
  color: black;
}

.sidebar-title {
  font-size: 14px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
}

.sidebar-title::after {
  content: "";
  display: block;
  width: 65px;
  height: 3px;
  margin: 5px auto;
  background: linear-gradient(to right, #fd6a01, #1d407f);
}

.search-field,
.subscription-field {
  box-sizing: border-box;
}

.search-button,
.subscription-button {
  width: 100%;
}

.tag-link {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  border: 2px solid #eeeeee;
}

.tag-link:hover {
  border: 2px solid #d8d8d8;
}

.category-link {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.category-link:hover {
  color: #1d407f;
}

.category-count {
  font-size: 12px;
  color: #1d407f;
}

a.new-posts-link {
  font-size: 15px;
  color: #1d407f;
}

.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* Required for the icon positioning */
  padding: 10px;
  border: 1px solid #e5e5e5;
}

.search-box input[type="search"] {
  width: 100%;
  max-width: 500px;
  padding: 0.75rem 3rem 0.75rem 1rem;
  /* Add padding-right to make space for the icon */
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-box input[type="search"]:focus {
  border-color: #1d407f;
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
}

/* Icon styling */
.search-box .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 60px;
  transform: translateY(-50%);
  background-color: orange;
  color: white;
  padding: 13px 20px;
  border-radius: 0px 20px 20px 0px;
  height: 70%;
}

.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clamped-text1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

article.article.d-flex {
  /* border-bottom:  1px solid #d4d4d4; */
  padding-bottom: 20px;
}

.new-posts-list {
  padding: 10px;
  border: 1px solid #e5e5e5;
}

article.new-posts-article.d-flex.mb-3 {
  border-bottom: 1px solid grey;
}

img.article-img.img-fluid {
  max-width: 400px;
  height: 265px;

  border-radius: 10px;
}

.auth {
  display: flex;
  justify-content: start;
  align-items: center;
}

img.article-img.img-fluid {
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.24);
}

img.article-img.img-fluid:hover {
  border: 1px solid white;
  transform: scale(1.1);
}

.category-item p {
  margin: 0px !important;
  cursor: pointer;
}