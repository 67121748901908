#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0d035e;
    z-index: 9999;
    animation: fadeOut 1s ease forwards 2s; 
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden; 
    }
}

#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/loader.gif);
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%);
}
