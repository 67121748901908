/*footer*/
/*top-addrsss*/

.footer {
  position: relative;
}

.footer-top-img img {
  padding: 0;
  /* Remove default padding */
}

.top-details-icon {
  background: #10429a;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  line-height: 60px;
  float: left;
  color: #ffffff;
  font-size: 29px;
  text-align: center;
}

/*top-btn-css*/
#back-to-top-btn {
  /* display: none; */
  position: fixed;
  bottom: 20px;
  right: 35px;
  font-size: 26px;
  width: 50px;
  height: 50px;
  background-color: #fd6a01;
  color: #333;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: background-color, color;
  border: 1px solid white;
  z-index: 2;
}

#back-to-top-btn img {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

#back-to-top-btn:hover {
  background-color: #10429a;
}

.top-details-text h3 {
  color: #14212b;
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.top-details {
  border: 1px solid #f3f5fa;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  box-shadow: 5px 5px 0px #10429a0d;
  transition: 0.5s;
}

.top-details-icon {
  transition: 0.5s;
}

.top-details:hover {
  box-shadow: 5px 5px 0px #ff6117;
}

.top-details:hover .top-details-icon {
  background: #ff6117;
}

.item-icon {
  width: 30%;
}

.top-details-text {
  width: 70%;
  margin: 0 0 0 13px;
}

/*top-footer*/
.top-footer {
  text-align: center;
  background: linear-gradient(to top, #fdbb0173, #ffed6c00);
  overflow: hidden;
  position: relative;
}

.footer-top-img img {
  width: 100%;
}

.parra {
  -webkit-animation: DownUpParra 3s infinite alternate;
  animation: DownUpParra 3s infinite alternate;
  margin: 0px;
  top: 25px;
  left: 50%;
  position: absolute;
}

.parra img {
  width: 100%;
  max-width: 45px;
}

@keyframes DownUpParra {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(-10px);
  }
}

.parra1 {
  -webkit-animation: DownUpParra1 3s infinite alternate;
  animation: DownUpParra1 3s infinite alternate;
  margin: 0px;
  top: 40px;
  left: 30%;
  position: absolute;
}

.parra1 img {
  width: 100%;
  max-width: 25px;
}

@keyframes DownUpParra1 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.parra2 {
  -webkit-animation: DownUpParra2 3s infinite alternate;
  animation: DownUpParra2 3s infinite alternate;
  margin: 0px;
  top: 40px;
  right: 10%;
  position: absolute;
}

.parra2 img {
  width: 100%;
  max-width: 55px;
  opacity: 0.5;
}

@keyframes DownUpParra2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*main-footer*/
.main-footer {
  background: url(../images/footer-bg.png) no-repeat center center;
  padding: 70px 0 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-attachment: fixed;
  text-align: left;
}

.main-footer:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0c1687;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
}

.footer-text img {
  width: 100%;
  max-width: 200px;
  margin: 0 0 30px;
}

.footer-text p {
  color: #e8e8e8;
  font-weight: 300;
  text-align: initial;
  font-size: 14px;
}

.footer-text h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  margin: 0px 0 30px;
  transition: 0.5s;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  position: relative;
}

.footer-text h4:before {
  position: absolute;
  content: "";
  width: 70px;
  height: 3px;
  background: #fd6a01;
  bottom: -10px;
}

.footer-text ul {
  padding: 0;
  margin: 30px 0 0;
  transition: 0.5s;
}

.footer-text li {
  padding: 0;
  margin: 0;
  transition: 0.5s;
  list-style-type: none;
  display: list-item;
  color: #e8e8e8;
  font-weight: 300;
  font-size: 14px;
  line-height: 40px;
  text-align: left;
}

.footer-text li a {
  font-size: 14px;
  color: #e8e8e8;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin: 0;
  text-decoration: none;
  line-height: 40px;
  transition: 0.5s;
}

.footer-text li a:hover {
  color: #fb954c;
}

.footer-text span {
  color: #fd6a01;
  font-size: 20px;
  margin: 0 5px 0 0px;
}

.social-icon {
  padding: 0;
  margin: 0;
}

.social-icon li {
  display: inline-block;
}

.social-icon li a {
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0;
  text-decoration: none;
  line-height: 35px;
  transition: 0.5s;
}

.social-icon span {
  width: 50px;
  height: 50px;
  background: #f3f1f1;
  font-size: 22px;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  color: #0b0f70;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.social-icon span:hover {
  background: #fd6a01;
  color: #fff;
}

.copy-left {
  font-size: 14px;
  color: #bdbdbd;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 30px 0 0;
  text-align: left;
}

.copy-left p {
  color: #e8e8e8;
  font-size: 14px;
  font-weight: 200;
}

.copy-right {
  font-size: 14px;
  color: #bdbdbd;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 30px 0 0;
  text-align: right;
}

.copy-right p {
  padding: 0 0 30px;
  margin: 0;
  text-align: right;
}

.hr {
  width: 100%;
  border-top: 1px solid #ffffff1f;
  margin: 30px 0 0;
}

.copy-right p a {
  color: #bdbdbd;
  text-decoration: none;
  transition: 0.5s;
}

.copy-right p a:hover {
  color: #fb954c;
}

.star {
  display: inline-block;
  position: absolute;
  top: 115px;
  right: 490px;
}

.star img {
  animation: rotated 10s infinite linear;
  width: 10px;
}

@keyframes rotated {
  from {
    -webkit-transform: rotate(360deg) translateX(50px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(0deg) translateX(50px) rotate(-360deg);
  }
}

.star1 {
  display: inline-block;
  position: absolute;
  top: 100px;
  right: 490px;
}

.star1 img {
  animation: rotated1 10s infinite linear;
  width: 10px;
}

@keyframes rotated1 {
  from {
    -webkit-transform: rotate(-360deg) translateX(50px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(0deg) translateX(50px) rotate(-360deg);
  }
}

.star2 {
  display: inline-block;
  position: absolute;
  top: 80px;
  right: 690px;
}

.star2 img {
  animation: rotated2 10s infinite linear;
  width: 15px;
}

@keyframes rotated2 {
  from {
    -webkit-transform: rotate(-360deg) translateX(100px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(0deg) translateX(100px) rotate(-360deg);
  }
}

.star3 {
  display: inline-block;
  position: absolute;
  top: 169px;
  left: 190px;
}

.star3 img {
  animation: rotated3 10s infinite linear;
  width: 15px;
}

@keyframes rotated3 {
  from {
    -webkit-transform: rotate(0deg) translateX(100px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(0deg) translateX(100px) rotate(-360deg);
  }
}

.shap1 {
  display: inline-block;
  position: absolute;
  bottom: 290px;
  left: 220px;
}

.shap1 img {
  animation: rotated 10s infinite linear;
  width: 20px;
  border-radius: 50%;
}

@keyframes rotated {
  from {
    -webkit-transform: rotate(0deg) translateX(50px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(0deg) translateX(50px) rotate(-360deg);
  }
}

.cricle {
  width: 225px;
  height: 230px;
  border-radius: 0 50px 0 50px;
  border: 30px solid #ffffff14;
  position: absolute;
  top: 30px;
  left: 85px;
  z-index: -1;
  animation: zoom-in-zoom-out 20s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.cricle1 {
  width: 200px;
  height: 220px;
  border-radius: 0 50px 0 50px;
  border: 30px solid #ffffff14;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: -1;
  animation: zoom-in-zoom-out1 20s ease infinite;
}

@keyframes zoom-in-zoom-out1 {
  100% {
    transform: scale(1.2, 1.2);
  }

  50% {
    transform: scale(1, 1);
  }

  0% {
    transform: scale(1.2, 1.2);
  }
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1.1);
  }
}

.association-images {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 10px;
}