.blog-details {
    width: 80%;
    height: 100%;
    margin: 130px auto 0px;
}

main {
    width: 100%;
    margin-bottom: 30px;
}

main section:not(.section1) {
    margin-top: 30px;
}

main .section1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.unknown-author-link {
  color: gray;
    font-size: 18px;
  }
  
.blog-line {
    width: 100%;
    height: 1px;
    background: #80828494;
}
main .section1 h1 {
    font-size: 30px;
    font-weight: 800;

}
.blog-details-sectin-1-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog-details-section-3-share-post-icons {
    font-size: 40px;

   
}
main .section1 > div div img {
    position: absolute;
    top: 5px;
}
.sec-img{
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
main .section1 > div p {
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    color: #4F4F4F;
}

.section-img {

    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.24);

  border-radius: 10px;
}
main .section3 {
    display: flex;
    width: 100%;
    column-gap: 20%;
}

main .section3 .blog-details-section-3-share {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post, 
main .section3 .blog-details-section-3-share .recipe-instructions {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post > h2, 
main .section3 .blog-details-section-3-share .recipe-instructions > h2 {
    font-size: 20px;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post .blog-details-section-3-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post .blog-details-section-3-list h5 {
    font-size: 13px;
    text-transform: capitalize;
    font-style: italic;
    color: #333333;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post .blog-details-section-3-list .inner-lists {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

main .section3 .blog-details-section-3-share .blog-details-section-3-share-post .blog-details-section-3-list .inner-lists .list-group {
    display: flex;
    column-gap: 15px;
    align-items: flex-start;
}


main .section3 .blog-details-section-3-share .recipe-link {
    margin-top: 20px;
}

main .section3 .blog-details-section-3-share .recipe-link a {
    text-decoration: none;
}

main .section3 .blog-details-section-3-share .recipe-link a span {
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    color: #BDBDBD;
}

main .section3 .recipe-details {
    width: 20%;
}

main .section3 .recipe-details .recipe-details-inner {
    width: 100%;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group {
    width: 100%;
    display: flex;
    column-gap: 5%;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group .group-image {
    width: 25%;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group .group-image span {
    font-size: 25px;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group .group-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group .group-details h6 {
    font-size: 8px;
    text-transform: uppercase;
    color: #BDBDBD;
}

main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group .group-details p {
    font-size: 11px;
    color: #333333;
}

.top-title {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media screen and (max-width: 1200px) {
     main .section3 {
        column-gap: 10%;
    }

     main .section3 .blog-details-section-3-share {
        width: 100%;
    }

     main .section3 .recipe-details {
        width: 25%;
    }
}

@media screen and (max-width: 900px) {


     main .section3 {
        column-gap: 5%;
    }

     main .section3 .blog-details-section-3-share {
        width: 100%;
    }

     main .section3 .recipe-details {
        width: 30%;
    }
}



@media screen and (max-width: 540px) {
    .comment-header {
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
}


@media screen and (max-width: 425px) {
    .replies {
      
         margin-left: 1px !important;
    }
}
@media screen and (max-width: 400px) {
    .coment-header-section-first {
        flex-direction: column;
        align-items: flex-start;
    }
    .comment-login {
      flex-direction: column;
    }
    .blog-details-sectin-1-title {
    
      flex-direction: column;
      align-items: self-start;
    }
}

@media screen and (max-width: 768px) {
    .blog-details-section-3-recent-post-data {
     
        flex-direction: column;
    }
     main .section1 {
        width: 100%;
    }

     main .section3 {
        flex-direction: column-reverse;
        row-gap: 25px;
    }

     main .section3 .blog-details-section-3-share {
        width: 100%;
    }

     main .section3 .recipe-details {
        width: 100%;
    }

     main .section3 .recipe-details .recipe-details-inner {
        flex-direction: row;
        flex-wrap: wrap;
        min-height: auto;
        row-gap: 20px;
    }

     main .section3 .recipe-details .recipe-details-inner .recipe-details-inner-group {
        width: 100px;
    }
}
.blog-details-section-3-recent-post-data {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
img.recent-image.mb-3 {
   
  height: 200px;
  width: 100%;
}
/* General Styling */
.comments-section {
    padding: 20px;
  }
  .author-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .blog-line {
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin: 15px 0;
  }
  
  /* Headings */
  .comments-title,
  .comments-count,
  .comment-header h5,
  .comment-author {
    color: #333;
    margin: 0;
  }
  
  /* Comment Header */
  .comments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .comments-count {
    font-weight: bold;
    font-size: 18px;
  }
  
  .sort-options {
    font-size: 14px;
    color: #777;
  }
  
  /* Recommendation Section */
  .recommendation {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  .recommendation-number {
    margin-right: 5px;
    background-color: #f0f0f0;
    padding: 2px 6px;
    border-radius: 3px;
  }
  
  /* Comment Wrapper */
  .comment-wrapper {
    margin-top: 15px;
  }
  
  .comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 30px;
    gap: 20px;
    background: #e9ecef82;
    padding: 10px;
    border-radius: 10px;
}
  .comment-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .comment-details {
    display: flex;
    flex-direction: column;
  }
  
  .comment-author {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .comment-date {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
  
  .comment-text {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .comments-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .comment {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .comment-avatar {
      margin-bottom: 10px;
    }
  }
  .comment-header {
    display: flex;
    justify-content: space-between;
}
.coment-header-section-first {
    display: flex;
    align-items: center;
    gap: 20px;
}
.sort-options {
    position: relative;
  }
  
  .sort-options .absolute {
    position: absolute;
    top: 100%; /* Position dropdown below the button */
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure dropdown is above other content */
  }
  
  .sort-options .absolute ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .sort-options .absolute li {
    padding: 8px 16px;
  }
  
  .sort-options .absolute li:hover {
    background-color: #f0f0f0;
  }
  .reply-box {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
  }
  
  .reply-input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    margin-bottom: 10px;
    outline: none;
  }
  
  .send-button {
    align-self: flex-end;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  .replies {
    /* display: flex; */
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-top: 10PX;
    width: 200px;
    position: relative;
}
.add-comment {
    padding: 30px;
    display: flex;
    justify-content: start;
    flex-direction: column;
}
.reply {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* width: 200px; */
    gap: 10px;
}
.thumbs-up-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #007bff;
  }
  
  .thumbs-up-count {
    margin-left: 8px;
    font-size: 16px;
  }
  .thumb {
    display: flex;
    
    align-items: center;
    gap: 20px;
}
.like {
    display: flex;
    justify-content: center;
    align-items: center;
}
.replay-image {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.dropdown-menu-delete {
    display: block;
    position: absolute;
    top: 28px;
    right: -100px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
  
  .dropdown-item-delete {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
  }
  
  .dropdown-item-delete:hover {
    background-color: #f1f1f1;
  }
  
.comment-login {
    display: flex;
    justify-content: space-between;
}






/* General modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .box-modal-google {
    background-color: white;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001; /* Ensure it's above the overlay */
    text-align: center;
  }
  
  .google-signup-btn {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
  .google-signup-btn svg {
    margin-right: 8px;
  }
  
  .close-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .close-btn:hover {
    background-color: darkred;
  }
  .google {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.close-icon-google{
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 9999;

}
textarea.comment-input {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
}
@media (max-width: 425px) {
  .top-title {

    flex-direction: column-reverse;
    align-items: start;
    gap: 20px;

  }
  .social-icons {
    font-size: 30px;
}
}
.social-icons {
  display: flex;
  gap: 5px;
}
.blog-details-section-3-list a {
  color: blue; 
  text-decoration: underline; 
}
