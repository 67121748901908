/* Common styles for both desktop and mobile */



.full-form {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 0.34rem;
    width: 100%;
}

.title {
    margin: 0 auto;
    padding-left: 50px;
}

.header {
    background: #10429a;
    color: #fff;
    letter-spacing: 0.5px;
    padding: 0;
    margin: 0;
}

.submit-btn {
    background: linear-gradient(to right, #ff5917, #ff9317);
    border: 0;
    padding: 8px 20px;
    font-size: 14px;
}

.custom-input {
    font-size: 14px;
}

/* Style for error messages */
.error {
    color: red;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 767px) {
    .imported-btn {
        padding: 7px 10px;
        /* Further reduce padding for smaller screens */
        margin-bottom: 10px;
        /* Restore margin for better spacing on smaller screens */
    }

    .title {
        padding-left: 20px;
        /* Adjust the title padding for smaller screens */
    }

    .full-form {
        padding: 0.2rem;
        /* Adjust padding for smaller screens */
    }

    .submit-btn {
        padding: 8px 12px;
        /* Adjust button padding for smaller screens */
        font-size: 12px;
        /* Decrease font size for smaller screens */
    }
}

/* Rest of your styles remain the same */