@media only screen and (min-width: 1199px) {
}

@media only screen and (min-width: 1920px) {
}

@media only screen and (max-width: 1024px) {
  .opening1 {
    margin: -98px 0 0 !important;
  }

  .services-text .item-contant {
    padding: 15px;
  }

  .top-details-text h3 {
    font-size: 14px;
  }

  .top-details {
    padding: 10px;
  }

  .top-details-text p {
    font-size: 13px;
    text-align: left;
  }

  .top-cloud1 {
    margin: -85px 0 0;
  }

  .top-cloud {
    margin: -104px 0 0;
  }

  .gallery_product .temp {
    height: 10px;
  }

  #img {
    height: 10px;
  }

  /*inner about*/
  .inner-header {
    margin: -78px 0 0;
  }

  .cricle2 {
    left: 330px;
  }
}

@media only screen and (max-width: 768px) {
  .new-nav ul li a {
    font-size: 12px;
    padding: 2px 7px;
  }

  .logo h2 {
    font-size: 26px;
  }

  .top-cloud1 {
    margin: -51px 0 0;
  }

  .top-cloud {
    margin: -70px 0 0;
  }

  .slider-img img {
    margin-right: -360px;
  }

  .slider-text h2 {
    font-size: 38px;
    line-height: 45px;
  }

  .section-type-text:before {
    display: none;
  }

  .section-type .col-md-6:nth-child(3) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-6:nth-child(4) .section-type-text {
    margin: 60px 0 0;
  }

  .why-us .col-md-6:nth-child(3) .why-text {
    margin: 30px 0 0;
  }

  .why-us .col-md-6:nth-child(4) .why-text {
    margin: 30px 0 0;
  }

  .opening1 {
    margin: -60px 0 0 !important;
  }

  .opening {
    margin: -70px 0 0 !important;
  }

  .top-addrsss .col-md-6:nth-child(3) .top-details {
    margin: 30px 0 0;
  }

  .copy-left p {
    text-align: center;
  }

  .copy-right p {
    text-align: center !important;
  }

  .career-text {
    padding: 0;
  }

  .footer-text .social-icon span {
    width: 35px;
    height: 35px;
  }

  .top-details-icon {
    background: #10429a;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    float: left;
    color: #ffffff;
    font-size: 19px;
    text-align: center;
  }

  .top-details-text h3 {
    font-size: 13px;
  }

  .why-text {
    height: auto;
  }

  .main-nav {
    padding: 5px 0;
  }

  #img {
    height: 10px;
  }

  /*inner about*/
  .cricle2 {
    left: 200px;
  }

  .big-heading h5 {
    font-size: 35px;
  }

  .csr-text,
  .approch-text,
  .vnm-text {
    padding: 30px;
  }

  /*services page*/
  /*portfolio.php*/
  .filter-button {
    padding: 10px 28px;
    margin: 0 0 12px;
  }

  /*contact*/
  .inner-con-left {
    padding: 30px;
  }

  .inner-con-right {
    padding: 100px 17px;
  }
}

@media only screen and (max-width: 736px) {
  .opening {
    margin: 0px;
  }
  .opening1 {
    margin: 0px;
  }
  .navbar-collapse {
    background: #1d3c78;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 62px;
    z-index: 500;
  }

  .main-slider {
    z-index: -1;
  }

  .new-nav ul li a {
    line-height: 45px;
  }

  .slider-text {
    top: 130px;
    left: 4px;
  }

  .navbar-nav {
    text-align: center;
  }

  .new-nav .navbar-toggler {
    border: 0px solid #ffffffa6;
    line-height: 30px;
  }

  .new-nav .navbar-toggler span .fa {
    color: #fff;
    font-size: 30px;
  }

  .navbar-collapse {
    display: none;
  }

  .new-nav .contact_btn {
    margin: 15px;
  }

  .why-us .col-md-3:nth-child(3) .why-text {
    margin: 30px 0 0;
  }

  .why-us .col-md-3:nth-child(4) .why-text {
    margin: 30px 0 0;
  }

  .section-type .col-md-3:nth-child(3) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-3:nth-child(4) .section-type-text {
    margin: 60px 0 0;
  }

  .video-text-right {
    margin: 0;
  }

  .video-text-right h6 {
    font-size: 35px;
  }

  .top-addrsss .col-md-6:nth-child(2) .top-details {
    margin: 30px 0 0 !important;
  }
  .top-addrsss .col-md-6:nth-child(3) .top-details {
    margin: 30px 0 0 !important;
  }

  .testi-text {
    height: auto;
  }

  /*inner about*/
  .cricle2 {
    left: 200px;
  }

  .big-heading h5 {
    font-size: 26px;
  }

  .csr-text,
  .approch-text,
  .vnm-text {
    padding: 10px;
  }

  .inner-header {
    z-index: 1;
    padding: 118px 0 0;
  }

  .counter-Txt {
    padding: 29px 0 0;
  }

  .navbar-toggler {
    z-index: 500;
  }

  /*services page*/
  .top-details-text h3 {
    font-size: 18px;
  }

  .inner-contact {
    background-position-x: 400px;
    background-size: 35%;
  }

  .inner-con-right {
    padding: 50px 17px 50px;
  }

  .inner-con-left {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 426px) {
  .opening1 {
    margin: -35px 0 0 !important;
  }

  .opening {
    margin: -35px 0 0 !important;
  }
  .navbar-collapse {
    background: #ffc746;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 62px;
    z-index: 500;
  }

  .main-slider {
    z-index: -1;
  }

  .new-nav ul li a {
    line-height: 45px;
  }

  .navbar-nav {
    text-align: center;
  }

  .navbar-nav li {
    border-bottom: 1px solid #2b2b2b;
  }

  .new-nav ul li a {
    color: #000;
  }

  .slider-text {
    top: -70px;
  }

  .slider-img img {
    margin-right: -640px;
  }

  .main-slider img {
    width: auto;
  }

  .main-slider .bx-wrapper img {
    max-width: initial;
  }

  .top-cloud1 {
    margin: -90px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .top-cloud {
    margin: -120px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .top-cloud1 img {
    width: 1090px;
  }

  .top-cloud img {
    width: 1380px;
  }

  .slider-text {
    margin: 30px 0 0;
  }

  .slider-text h2 {
    font-size: 43px;
    line-height: 60px;
    margin: 120px 120px 20px 0px;
    text-align: left;
  }

  .slider-text p {
    text-align: left;
  }

  .slider-text .contact_btn {
    display: none;
  }

  .tnb {
    padding: 70px 0;
  }

  .section-type .col-md-3:nth-child(3) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-3:nth-child(2) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-3:nth-child(4) .section-type-text {
    margin: 60px 0 0;
  }

  .why-us .col-md-3:nth-child(2) .why-text {
    margin: 30px 0 0;
  }

  .why-us .col-md-3:nth-child(3) .why-text {
    margin: 30px 0 0;
  }

  .why-us .col-md-3:nth-child(4) .why-text {
    margin: 30px 0 0;
  }

  .top-addrsss .col-md-4:nth-child(2) .top-details {
    margin: 20px 0;
  }
  .top-addrsss .col-md-4:nth-child(3) .top-details {
    margin: 20px 0;
  }

  .video-text-right {
    text-align: center;
  }

  .opening {
    margin: -30px 0 0;
  }

  .testi-text {
    height: auto;
  }

  .main-footer {
    text-align: center;
  }

  .footer-text p {
    text-align: center;
  }

  .footer-text h4:before {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .footer-text .social-icon span {
    width: 50px;
    height: 50px;
  }

  .footer-text h4 {
    margin: 30px 0 0;
  }

  .abut-img {
    margin: 0 0 30px;
  }

  header.sticky .new-nav ul li a {
    color: #000;
  }

  .new-nav .contact_btn {
    margin: 15px;
  }

  .services .col-md-4:nth-child(5) .services-text {
    margin: 30px 0 0;
  }

  .services .col-md-4:nth-child(6) .services-text {
    margin: 30px 0 0;
  }

  .opening1 {
    margin: -105px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .opening {
    margin: -120px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .opening1 img {
    width: 1030px;
  }

  .opening img {
    width: 1250px;
  }

  .footer-top-img img {
    width: auto;
  }

  /*inner about*/
  .inner-header {
    background-size: cover;
  }

  .cloud3 img {
    width: 860px;
  }

  .inner-text img {
    max-width: 70px;
    margin: 0 300px 0;
  }

  .approch-text {
    margin: 30px 0 0;
  }

  .csr-text,
  .approch-text,
  .vnm-text {
    padding: 25px;
  }

  /*portfolio*/
  .filter-button {
    padding: 10px 11px;
    margin: 0 0 12px;
  }

  /*contact*/
  .inner-contact {
    background-position-x: 227px;
    background-size: 40%;
  }

  .inner-con-right h4 {
    padding: 0;
  }
  .main-footer .footer-text {
    text-align: center !important;
  }
  .main-footer .footer-text p {
    text-align: center !important;
    padding: 0.9rem;
  }
  .main-footer .footer-text .social-icon {
    text-align: center;
  }
  .main-footer .footer-text {
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .section-type .col-md-6:nth-child(3) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-6:nth-child(2) .section-type-text {
    margin: 60px 0 0;
  }

  .section-type .col-md-6:nth-child(4) .section-type-text {
    margin: 60px 0 0;
  }

  .why-us .col-md-6:nth-child(2) .why-text {
    margin: 30px 0 0;
  }

  .why-us .col-md-6:nth-child(3) .why-text {
    margin: 30px 0 0;
  }

  /*portfolio*/
  .filter-button {
    padding: 10px 5px;
    margin: 0 0 10px;
  }

  .inner-con-right h4 {
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .filter-button {
    padding: 10px 22px;
  }

  .slider-text h2 {
    font-size: 34px;
    line-height: 45px;
  }
}
