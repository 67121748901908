.pagination_rounded,
.pagination_square {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap; 
}

.pagination_rounded ul {
  display: flex;
  padding: 0;
  list-style: none;
  gap: 5px; 
}

.pagination_rounded ul li {
  margin: 0;
}

.pagination_rounded ul li a {
  color: #FFA500;
  border-radius: 50%;
  line-height: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  font-size: 14px; 
}

.pagination_rounded ul li a:hover {
  background: #FFA500;
  color: #fff;
  border: 1px solid #FFA500;
}

.pagination_rounded .prev,
.pagination_rounded .next {
  border-radius: 35px;
  width: auto;
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_rounded .prev.disabled,
.pagination_rounded .next.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pagination_rounded ul li a.active {
  background: #FFA500;
  color: #fff;
  border: 1px solid #FFA500;
}

.hidden-xs {
  display: none;
}

.visible-xs {
  display: inline-block;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pagination_rounded ul li a {
    height: 28px;
    width: 28px;
    font-size: 12px; /* Reduce font size for smaller screens */
    line-height: 28px;
  }

  .pagination_rounded .prev,
  .pagination_rounded .next {
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 30px;
  }

  .pagination_rounded ul {
    gap: 3px; /* Reduce gap between items */
  }
}

@media (max-width: 480px) {
  .pagination_rounded ul li a {
    height: 26px;
    width: 26px;
    font-size: 11px; /* Smaller font size for very small screens */
    line-height: 26px;
  }

  .pagination_rounded .prev,
  .pagination_rounded .next {
    height: 28px;
    padding: 0 8px;
    font-size: 11px;
    line-height: 28px;
  }

  .pagination_rounded ul {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .visible-xs {
    display: block; 
  }

  .hidden-xs {
    display: none; 
  }
}
