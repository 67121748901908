.error-container {
  display: flex;
  padding-top: 50px;
}

.name {
  color: red;
}

.err404 {
  font-size: 6rem;
  font-weight: bold;
  color: white;
}

.bg {
  background: #08085a;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
  text-align: center;
}

img.img404 {
  width: 300px;
  transition: transform 0.3s ease-out;
}

.go-back {
  border-radius: 30px;
  color: #090979;
  font-weight: 600;
  background-color: beige;
  border: 2px solid #1d1dbd;
  padding: 0.5rem 3rem;
  display: block;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  box-shadow: #9898c075 2px 4px 4px 0px;
}

.go-back:hover {
  border: 2px solid white;
  text-decoration: none;
  background: #090979;
  color: white;
}

.digit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.digit {
  display: inline-block;
  margin: 0 -8px;
  color: orange;
  z-index: 1;
  text-shadow: #9898c075;
}

.zero {
  font-size: 9rem;
  z-index: 0;
}

.flip-bounce {
  animation: flip-bounce 5s ease-in-out infinite, color-change 5s infinite;
}

@keyframes flip-bounce {
  0%,
  20%,
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-2px);
  }
}

@keyframes color-change {
  0%,
  100% {
    color: rgb(234, 230, 230);
  }
  50% {
    color: orange;
  }
}
