.imported-btn {
    color: #fff;
    background: linear-gradient(to right, #ff5917, #ff9317);
    font-size: 12px;
    padding: 7px 28px;
    display: inline-block;
    border-radius: 30px;
    letter-spacing: 0.5px;
    margin: 10px 0 0px;
    text-decoration: none;
    transition: 0.5s;
    border: none; /* This line removes the border */
}

.imported-btn:hover {
    background: linear-gradient(to right, #ff9317, #ff5917)
}

/* PHP */
/*inner-career*/


.inner-career-text {
    box-shadow: 0px 4px 21px -4px rgba(16, 66, 154, 0.43);   
     padding: 10px;
    display: flex;
    transition: 0.5s;
    margin: 0 0 30px;
    z-index: -1;
}

.career-icon{
    float: left;
    font-size: 50px;
    background: #f1f1f1;
    width: 80px;
    text-align: center;
    color: #10429a;
    border-radius: 5px;
    margin: 0 10px 0px 0;
    padding: 5px;
    height: fit-content;
}

.icon-text {
    width: 100%;
}

.icon-text h4 {
    color: #31343a;
    font-size: 18px;
}

.icon-text .fa {
    color: #10429a;
}

.icon-text p {
    margin: 0;
    color: #504444;
    font-size: 14px;
}

.inner-career-text:hover {
    background: #10429a;
}

.inner-career-text:hover h4 {
    color: #fff;
}

.inner-career-text:hover p {
    color: #fff;
}

.inner-career-text:hover .icon-text .fa {
    color: #fff;
}

.custom-modal .modal-content select {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 0.34rem;
    width: 100%;
}


.custom-modal .modal-title {
    margin: 0 auto;
}

.custom-modal .modal-header {
    background: #10429a;
    color: #fff;
    letter-spacing: 0.5px;
}

.custom-modal .modal-header .close {
    padding: 0;
    margin: 0;
}

.custom-modal .btn-primary {
    background: linear-gradient(to right, #ff5917, #ff9317);
    border: 0;
    padding: 8px 20px;
    font-size: 14px;
}

.custom-modal .form-control {
    font-size: 14px;
}

.inner-career .big-heading h5 {
    text-align: center;
    margin: 0 0 40px;
}

.inner-career .text-center {
    margin: 0 0 30px;
}

.inner-career .text-center span {
    font-size: 20px;
}

.inner-career .small-heading {
    text-align: center;
    display: inline-block;
    padding: 0 50px 0px 50px;
    color: #fff;
}

.inner-career .small-heading:after {
    position: absolute;
    width: 37px;
    height: 3px;
    background: #ff4a17;
    top: 10px;
    right: 0;
    content: '';
}

/* PHP */
.job-icon{
    height: 50px;
}