
/* .cloud-cloud{
    padding-top: 5rem;
} */


/*animation*/
.top-cloud {
    -webkit-animation: upDown 10s infinite alternate;
    animation: upDown 5s infinite alternate;
    position: absolute;
    margin: -127px 0 0;
    opacity: 0.5;
}

.top-cloud img {
    width: 100%;
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

.top-cloud1 {
    -webkit-animation: DownUp 10s infinite alternate;
    animation: DownUp 5s infinite alternate;
    position: relative;
    margin: -95px 0 0;
}

.top-cloud1 img {
    width: 100%;
}

@keyframes DownUp {
    100% {
        transform: translateY(0);
    }

    0% {
        transform: translateY(-10px);
    }
}

.opening {
    -webkit-animation: DownUpOpening 10s infinite alternate;
    animation: DownUpOpening 5s infinite alternate;
    position: relative;
    margin: -100px 0 0;
}

.opening img {
    width: 100%;
}

@keyframes DownUpOpening {
    100% {
        transform: translateY(0);
    }

    0% {
        transform: translateY(-10px);
    }
}

.opening1 {
    -webkit-animation: DownUpOpening1 10s infinite alternate;
    animation: DownUpOpening1 5s infinite alternate;
    position: relative;
    margin: -110px 0 0;
}

.opening1 img {
    width: 100%;
}

@keyframes DownUpOpening1 {
    100% {
        transform: translateY(0);
    }

    0% {
        transform: translateY(-10px);
    }
}


/*animation*/