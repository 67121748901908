* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
