/*policy*/

.inner-header{
    padding-top: 250px;
}


.tnc-text .fa {
    color: #fd6a01;
    font-size: 14px;
    padding: 0 6px 0px 0;
}

.tnc-text p {
    line-height: 30px;
}

.tnc-text h2 {
    font-size: 24px;
    color: #14212b;
    font-weight: 700;
    margin: 10px 0 10px;
    position: relative;
    padding: 0 0px 0 16px;
}

.tnc-text h2:before {
    position: absolute;
    width: 5px;
    height: 28px;
    background: #ff4a17;
    top: 0px;
    left: 0;
    content: '';
}