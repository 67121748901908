/*why-us*/
.why-us {
  text-align: center;
  background: #10429a url(../images/why-us-bg.png) no-repeat top;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}

.why-us .small-heading {
  text-align: center;
  display: inline-block;
  padding: 0 50px 0px 50px;
  color: #fff;
}

.why-us .small-heading h4 {
  color: #fff;
}

.why-us .big-heading h5 {
  color: #fff;
}

.why-us .small-heading:after {
  position: absolute;
  width: 35px;
  height: 3px;
  background: #fff;
  top: 10px;
  right: 0;
  content: "";
}

.why-us .small-heading:before {
  background: #fff;
  width: 35px;
}

.why-text img {
  max-width: 100%;
  transition: 0.5s;
  overflow: hidden;
}

.why-text:hover img {
  transform: scale(1.05);
}

.why-text {
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgb(255 255 255 / 45%);
  height: 510px;
  transition: 0.5s;
}

.why-text:hover {
  box-shadow: 0px 0px 20px 0px rgb(255 255 255 / 80%);
}

.why-text h4 {
  margin: 15px 0 30px;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  color: #39465f;
}

.why-text h4:before {
  position: absolute;
  left: 50%;
  bottom: -18px;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 3px;
  background: #10429a26;
  content: "";
}

.why-text h4:after {
  position: absolute;
  left: 50%;
  bottom: -18px;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 3px;
  background: #ff5f17;
  content: "";
  transition: 0.5s;
}

.why-text:hover h4:after {
  width: 100px;
}

/*.why-us .cloud1{bottom: -128px;}*/

@media (max-width: 768px) {
  .why-us .small-heading {
    /* padding: 0 20px; */
  }

  .why-us .small-heading:after {
    width: 35px;
  }

  .why-text {
    height: auto;
  }

  .why-text h4:before,
  .why-text h4:after {
    bottom: -10px;
  }
}

.why-1 {
  background-color: #00fefc;

  height: 150px;
}

.why-2 {
  background-color: #03b0ff;

  height: 150px;
}

.why-3 {
  background-color: #7e01ff;

  height: 150px;
}

.why-4 {
  background-color: #ff00f0;

  height: 150px;
}
